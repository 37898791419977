import React from 'react';
import logo from './img/logo.jpg';

const Hbar=()=>{
 
  return(
  <>
              <div className="hbar__div">
                <img className="hlogo" src={logo} />
                never lose a thought !
              </div>
  </>)
};



export default Hbar;