import React from 'react';


const Footer =()=>{
    return(<>
    <div className="footer__div bg-dark">
    <p className="text-center text-capitalize text-white bg-dark"> © Copyright Rupesh Singh 2023 </p>
    </div>
    
    </>)
};


export default Footer;
